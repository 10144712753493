h2,p{
  text-align: center;
}
/* body {
  overflow-x: hidden !important;
} */
@font-face {
  font-family: blanquotey;
  src: url(./assets/Blanquotey.ttf);
}
.bg-dark{
  background-color: black !important;
}
.bg-light{
  background-color: white !important;
}
.text-dark{
  color: black !important;
}
.bg-yellow{
  background-color: #FFE23F !important;
}
.text-yellow{
  color: #FFE23F !important;
}
.rounded-20{
  border-radius: 12px !important;
}
.text-webkit-center{
  text-align: -webkit-center !important;
  text-align: -moz-center !important
}
.text-webkit-right{
  text-align: -webkit-right !important;
  text-align: -moz-right !important
}
.text-webkit-left{
  text-align: -webkit-left !important;
  text-align: -moz-left !important
}
.align-self-center{
  align-self: center !important;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
/* login and register page css */
.signUpParent{
  height: 100vh;
  margin-top: 5.7em;
}
.mt-5em{
  margin-top: 5em !important;
}
.signUpParent .row{
  background-image: url(./assets/login_flip.png);
  background-repeat: no-repeat;
  background-size:cover !important;
  height: 100vh;
  --bs-gutter-x:0 !important;
}
.regParent .row {
  background-image: url(./assets/regPage.png);
}
.credentialCard{
  background-color: transparent !important;
  border-radius: 50px !important;
}
.credentialCard form input[type=email] , .credentialCard form input[type=password] ,  .credentialCard form input[type=text] , .credentialCard form input[type=number]{
    padding: 0px !important;
    background: transparent;
    border: none;
    border-bottom: 2px solid white;
    border-radius: 0px;
    height: 1.5em !important;
}
.credentialCard form input[type=email]:focus , .credentialCard form input[type=password]:focus , .credentialCard form input[type=number]:focus , .credentialCard form input[type=text]:focus{
  box-shadow: none !important;
  outline: none;
}
.blurbg {
  backdrop-filter: blur(8px);
  background-color: rgba(192, 192, 192, 0.152) !important;
  position: absolute;
  border-radius: 12px !important;
  background-size: cover;
  height: 30em;
  top: 0%;
  width: 25em;
  left: 10%;
  /* z-index: 3 !important; */
}
.login__screen .blurbg{
  height: 24em;
}
.signUpBtn button , .signUpBtn button:focus {
  width: 13em ;
  border-radius: 10px !important;
  box-shadow: none !important;
  outline: none !important;
  margin: 15px 0px 15px 0px;
}

a:hover{
cursor: pointer;
}

#learnMoreBtn,#moreDetails{
text-decoration: underline;
font-weight: 500;
font-size:1.2em;
}

.mg-t-20{
margin-top: 2em !important;
}

.mg-t-10{
margin-top: 1em !important;
}
.fontbold{
font-weight: bold;
}

.text-right{
text-align: right !important;
}

.basicSubText{
color:#7c7878;

}
.text-left{
text-align: left !important;
}
.mg-t-5{
margin-top: 5px;
}
.backdrop{
  background-color: rgba(0, 0, 0, 0.262);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
@media screen and (max-width:768.5px) {
  .backdrop .credentialCard{
    width: 60%;
    margin: auto;
  }
  .blurbg{
    margin: 12%;
    left: 0%;
  }
  .mt-5em{
    margin-top: 0em !important;
  }
  .signUpParent{
    margin-top: 3em;
  }
  
}

@media screen and (max-width:576.5px) {
  .backdrop .credentialCard{
    width: 60%;
    margin: auto;
  }
  .blurbg{
    width: auto;
    margin: 8%;
    left: 0%;
  }
  .review-textarea{
    width: 350px;
  }
  
}
/* .backdrop .credentialCard{
  z-index: 3 !important;
} */

#loginSignupModal #recaptcha div div{
  width: auto !important;
}