.bannerClass{
    /* position:relative; */
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    background:url(https://firebasestorage.googleapis.com/v0/b/adventrio-4b76b.appspot.com/o/assets%2FseaViewCompressed.jpeg?alt=media&token=fca1ab4b-3f53-4687-8d67-c0f70f45f417);
    background-size: cover;
    /* margin-top:-100px; */
    display:flex;
    justify-content: center;
    align-items: center;
    clip-path: ellipse(75% 100% at 50% 0%);
    

}

.bannerSubtitle h6 , .Typewriter .Typewriter__wrapper{
    font-size: 1.8rem;
    text-align: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 2px;
}
.Typewriter .Typewriter__wrapper{
    color: #ffe23f;
    letter-spacing: 6px;
}
.bannerSearchDiv{
    /* position: absolute;
    top:40%;
    left:50%;
    transform: translate(-50%,-50%); */
    margin-top: -5em;
}
.searchDiv input {
    border-radius: 8px !important;
    padding-left:2em ;
}
.searchDiv input:focus{
    box-shadow: none;
    outline:none;
}
.searchDiv input::placeholder{
    letter-spacing: 0.2rem;
}
.searchBar img{
    left:0.55em;
    width: 1.5em;
    top: 0.55em;
}
.filterIcons{
    margin-top: 1.5em;
    /* margin-left: 1.5em;
    margin-right: 1.5em; */
    display: flex;
    justify-content: space-between;
}
.serviceIcon{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: white;
    padding: 14px;
}
.serviceIcon img{
    width: 2em;
}

.travelService:hover + .serviceName{
    width: 200px !important;
}
.serviceName{
}
@font-face {
    font-family: blanquotey;
    src: url(../assets/Blanquotey.ttf);
  }
.bannerTitle h1{
    color:white;
    font-size: 5em;
    font-family: blanquotey  !important;
    letter-spacing: 0.5rem;
}
.bannerTitle h6{
    color:white;
    font-size: 1.5em;
    text-align: center;
    letter-spacing: 0.5em;
}
.bannerCurve{
    background-color: black;
}
.searchBar{
    z-index: 0;
}
.searchDiv .border-radius-class {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.search__results{
    position: absolute;
    top: -7px;
    padding-top: 20px;
    width: 100%;
}
.search_result_strip:hover{
    cursor: pointer;
    background-color: #ffe23f;
}
#search:focus{
    outline: none !important;
}
#search::placeholder{
    text-transform: capitalize !important;
}


/* expanding button on hover CSS */
.buttonBanner {
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 60px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    overflow:hidden;
    width: auto;
    max-width: 73px; /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
    -webkit-transition: max-width 0.5s ease-in-out;
    transition: max-width 0.5s ease-in-out;
  }
  
  .buttonBanner:hover {
    max-width: 300px;
    color: #000;
  }
  
  .icon {   
    padding: 0px 8px;
    display: flex;
    align-items: center;
  }
  .icon img{
    font-size: 29px;
  }
  
  .text {
    white-space: nowrap;
    padding-right: 15px;
  }

  /* Search tabs */
  .search__tabs p {
    margin-bottom: 0px;
    padding: 5px;
    cursor: pointer;
    border-left: 2px solid black;
    width: 100px;
  }
  .search__tabs p:hover{
    font-weight: 600;
    background-color: #ffe23f;
    transition: 0.3s ease-in-out;
  }
  .search__tabs p:nth-child(1):hover{
    border-top-left-radius: 8px;
    /* border-bottom-left-radius: 8px; */
  }
   .search__tabs p:nth-child(3):hover{
    border-top-right-radius: 8px;
    /* border-bottom-right-radius: 8px; */
  }

  
  .search__tabs{
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
    /* background-color: #ffe23f; */
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

/* media 768 */

@media screen and (max-width:768.5px) {
    .searchDiv{
        margin-top: 0em;
    }
    .bannerTitle h1{
        font-size:4em;
    }
    .bannerSubtitle h6 {
        font-size: 1.5em !important;
    }
    .Typewriter .Typewriter__wrapper{
        font-size: 1em !important;
    }
    .sectionHeading h1{
        font-size: 2.8em;
    }
    .bannerClass{
        clip-path: none !important;
    }
    .exploreMoreSeciton{
        margin-top: 20px;
    }
}

/* media 576 */

@media screen and (max-width:576.5px) {
  .overviewParent{
    background-color: black;
  }
  .sectionHeading h1{
    font-size: 2.5em;
  }
}


