.productDetails{
  margin-top: 7em;
  padding-left: 0.2em;
  padding-bottom: 0.2em;
  }
  .images{
  padding: 0.5em;
   position: relative;
  }
  /* product image slider  */

  .responsive-carousel .carousel{
    display: flex;
    justify-content: center;
  }
  .responsive-carousel .carousel-slider{
    border-radius: 12px;
    box-shadow: 0px 5px 10px #00000085;
  }
  .responsive-carousel .carousel .thumb img, .responsive-carousel .carousel .thumb.selected, .responsive-carousel .carousel .thumb:hover{
    border-radius: 6px;
    border-color: black;
  }
  .responsive-carousel .carousel .slide{
    height:60vh;
  }
  .responsive-carousel .carousel .slide img{
    max-height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .responsive-carousel .image{
    margin: 0 auto;
    height: 100%;
    width: auto;
  }
  .imageSection{
  width: fit-content;
  }
  .hoverClass:hover{
    cursor:pointer;
  }
  .viewMoreImage:hover{
    cursor: pointer;
  }
  .viewMoreImage + .viewMoreText{
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  
  .viewMoreText{
   cursor: pointer;
    display: none;
    position: absolute;
    font-weight: 600;
    color:#fff;
    transition: .5s ease;
    top: 0;
    text-align: center;
   background: rgba(0, 0, 0, 0.5);
     height: 84%;
    width: 94%;
    border-radius: 2em;
  }
  
  .headdingNamesArea{
  justify-content: space-between;
  }
  
  .commonButton{
    border-radius: 2em;
    background: #fff;
    padding: 0.4em 0.5em;
    border: 0.05em solid;
    width: max-content;
  }
  
  .icons{
  padding: 0.5em
  }

  .serviceCostSection{
   position: relative;
   bottom: 40px;
   right: 32px;
   /* z-index: 0; */
  
  }
  
  .cardBox{
  border-radius: 1em;
  border: 0.05em solid;
  padding: 0.5em 2.2em;
  }
  .supplylists{
  padding: 0.2em;
  padding-left: 1em;
  }
  .basicSubText{
  margin-left: .5em;
  }
  .icon img {
      width: 2em;
      height: 1em;
  }
  
  .text-bold-6{
    font-weight: 600;
  }
  
  .costSection1,.dateSection,.buttonSection{
  display: flex;
  justify-content: space-between;
  align-items: center
  }
  .onlyCartButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .guestList{
  width: 100%;
  border: 0;
  }
  .dateInput{
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none;
  width: 100%;
  }
  
  .checkDiv{
  padding:0 1em 0 0.2em;
  }
  
  
  .activity{
  margin: 1em;
  position: relative;
  }
  
  .paidbtn,.freebtn{
  position:absolute;
  bottom: -1.5em;
      left: 46px;
  border-radius: 1em ;
  font-weight: 500 !important;
  }
  
  .freebtn{
  background-color: #fff;
  border: 0.1em solid #FFE23F;
  padding: 0.6em 3em;
  }
  
  .propertyHood ,.reviewCard {
  border-radius: 1em;
  background-color: #fff;
  box-shadow: 1px 1px 4px #ccc;
  padding: 0;
  }
  .reviewCard{
  border-radius: 0.5em;
  padding: 0.5em;
  }
  .reviewSection{
  padding:0 4em;
  }

  .propertyHood img {
  border-radius: 1em;
  /* box-shadow: 1px 1px 4px #ccc; */
  width: -webkit-fill-available;
  }
  .propertyHood{
    margin-bottom: 1em;
    margin-right: 1em;
  }
  .under_the_hood{
    margin-right: 0px;
    padding: 5px;
  }
  .profileReview{
  display: flex;
  align-items: center;
  }
  .profileReview img{
  border-radius: 50%;
  width: 50px;
  }
  .profileReview .profileDes{
  margin-left:1em ;
  width: 100%;
  text-align: left;
  }
  .profileDes p {
    text-align: left;
  }
  .profileDes label{
  font-weight: bold;
  width: 100%;
  text-align: left;
  }
  .mainDiv2 .slick-slide{
  margin:0 !important;
  }
  .mainDiv2 .slick-list{
  padding: 0 !important;
  }
  .mainDiv2 .slick-dots{
  bottom: -2.5em !important ;
  }
  .reviewStar{
  color:rgba(255, 160, 51, 1)
  }
  .reviewRate span{
  padding: 0.1em;
  }

  .buttonSection .advButton{
    /* padding: 0.6em 0.61em; */
    /* width: 100%; */
    white-space: nowrap;
  }
  .reviewSection .slick-dots{
    bottom: 0em !important;
  }
  
  .hidee{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.5s linear;
  }
  .subCheckDates{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FFE23F;
    border: none ;
    outline: none;
    margin-top: 10px;
    padding: 13px;
  }
  .linksFor ,.linksFor:hover{
    text-decoration: none !important;
    color: black;
  }
  
  #checkedInDate::-webkit-calendar-picker-indicator , #activityDate::-webkit-calendar-picker-indicator{
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  
  #checkedOutDate::-webkit-calendar-picker-indicator , #activityTime::-webkit-calendar-picker-indicator{
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .react-datepicker__input-container input , .react-datepicker__input-container input:focus{
    border: none !important;
    border-bottom: 1px #ababab solid !important;
    outline: none !important;
    cursor: pointer;
    caret-color: transparent;
  }
  .slick-arrow.slick-prev{
    z-index: 0;
  }
  .reviewDesc{
    min-height: 12em;
    overflow: hidden;
    max-height: 12em;
  }

  .cardBox .activity img{
    width: 100%;
  }

  .descriptionSection h2 {
    font-size: 24px;
  }
  .descriptionSection h1{
    font-size: 32px;
    margin-left: 0px;
  }
  .descriptionSection h4, .descriptionSection h5{
    font-size: 18px;
  }

  .mobile_show{
    display: none;
  }
  @media screen and (min-width: 1025.5px){
    .innerProductDetails{
      display: flex;
    }
    .descriptionSection{
      padding:0em 2em 0em 4em;
      width: 70%;
    }
    .innerSectionServiceCost{
      background: #fff;
      padding: 2em 1em;
      height: 22em;
      width: 23em;
      -webkit-box-shadow: 0 0.2em 0.8em rgb(0 0 0 / 12%);
      box-shadow: 0 .2em .8em rgb(0 0 0 / 12%);
      position: sticky;
      border-radius: 2em;
      top: 6rem;
    }
  }
  @media screen and (min-width: 1024px){
    .innerProductDetails{
      display: flex;
    }
    .descriptionSection{
      padding:0em 2em 0em 4em;
      width: 70%;
    }
    .innerSectionServiceCost{
      background: #fff;
      padding: 2em 1em;
      height: 21em;
      width: 29em;
      -webkit-box-shadow: 0 0.2em 0.8em rgb(0 0 0 / 12%);
      box-shadow: 0 .2em .8em rgb(0 0 0 / 12%);
      position: sticky;
      border-radius: 2em;
      top: 6rem;
    }
    
  }

  @media screen and (max-width: 350.5px) {
    .total_guest span {
      flex: 0 !important;
    }
    .innerSectionServiceCost {
      padding: 0.5em !important;
  }
  }
  
  @media screen and (max-width:576.5px) {
    .innerSectionServiceCost{
      display: block !important;
      padding: 0.5em 1em
    }
    .innerProductDetails{
      /* margin: 4em 2em 0em 2em; */
    }
    .costSection1,.total_guest{
      flex-direction: row  !important;
    }
    .total_guest .qty{
      text-align: right !important;
      flex: 1;
    }
    .total_guest span {
      flex: 1;
    }
    .innerSectionServiceCost hr{
      display: block;
      margin: 0.01 em;
    }
    .webView{
      display: none !important;
    }
    .mobileView{
      display: block !important;
    }
    .mobileView .mainDiv2 .slick-list{
      min-height: 10em !important;
    }
    .cardBox .row{
      justify-content: flex-start;
    }
    .activity_row{
      justify-content: center;
    }
    .productName{
      font-size: 24px !important;
      margin-bottom: 0px;
    }
    .subHeadArea h4{
      font-size: 15px !important;
      font-weight: 600;
    }
    .descriptionSection h2 {
      font-size: 20px !important;
    }
    .descriptionSection h1{
      font-size: 24px !important;
    }
    .descriptionSection h4, .descriptionSection h5{
      font-size: 15px !important;
    }
    .responsive-carousel .carousel .slide img{
      height: -webkit-fill-available !important;
    }
  }

  
  @media (max-width:768.5px){
      
        .buttonSection .advButton{
        /* margin-top: 1em; */
        text-align: center;
        }
        .activity{
        padding: 1em;
        }

      .viewMoreImage{
        height: 170px;
      }
      .viewMoreText {
        left: 3px;
        height: 100% !important;
      }
      .innerProductDetails{
        /* margin: 0em 2em 0em 2em; */
      }
      .commonButton .btn_text{
        display: none;
      }
      .commonButton{
        border-radius: 50%;
        height: 2em;
        display:flex;
        align-items: center;
        justify-content: center;
        padding: 0.8em;
        width: 2em;
      }
      .supplylists{
        max-width: fit-content;
      }
   
   }


  @media (max-width:1024.5px) and (min-width:992.5px) {
    .supplylistsalt{
    display:none;
    }
    .paidbtn,.freebtn{
    padding: 0.6em 1.5em;
    }
    /* traceback css */
    .descriptionSection .description{
      font-size: 15px;
      margin: auto;
    }
    .suppliesSection {
      justify-content: center !important;
    }
    .amenititesText {
      font-size: 14px;
    }
    .activitiesSection .row{
      justify-content: space-evenly !important;
    }
    .activitiesSection .paidbtn{
      left: 3.5rem;
    }
    .activitiesSection .freebtn{
      left: 4.5rem;
    }
   /* .costSection1,.dateSection{
    flex-flow: column;
    } */
    /* .serviceCostSection{
    height: 15em;
    overflow-y: auto;
    } */
    .checkDiv h5 p {
    text-align: left !important;
    }
    .checkDiv{
    padding: 0;
    margin-top: 0.5em;
    }
    .buttonSection .advButton{
      /* padding: 0.6em 0.61em; */
      /* width: 100%; */
    }
  }
  @media (max-width:1024.5px){
    .serviceCostSection{
      position: inherit;
    }
    .innerSectionServiceCost{
      background: #fff;
      width: 100%;
      padding: 0.5em 0em;
      -webkit-box-shadow: 0 0.2em 0.8em rgb(0 0 0 / 12%);
      box-shadow: 0 .2em .8em rgb(0 0 0 / 12%);
      position: fixed;
      border-radius: 0;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .innerSectionServiceCost hr {
      display: none;
    }
    .total_guest{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .total_guest .qty{
      text-align: center !important;
    }
    .innerSectionServiceCost{
      padding: 14px 0px;
      font-size: 14px !important;
    }
    .innerSectionServiceCost .buttonSection , .innerSectionServiceCost .dateSection{
      flex-direction: column;
    }
    .innerSectionServiceCost .buttonSection #bookbtn{
      margin-bottom: 0px !important;
    }
    .innerSectionServiceCost .dateSection h6 p{
      margin-bottom: 0px;
      font-weight: 600;
    }
    .innerSectionServiceCost .total_guest span{
      font-weight: 600;
    }
    .desktop_show{
      display: none !important;
    }
    .mobile_show{
      display: block;
      position: fixed;
      bottom: 0px;
      z-index: 2;
    }
    .checkDiv input,.checkDiv select{
      background-color: white;
    }
  }

  @media screen and (max-width:991.5px) {
      .suppliesSection,.description {
        margin: 20px;
        font-size: 14px;

      }
      .descriptionSection h2 {
        font-size: 24px;
      }
      .descriptionSection h1{
        font-size: 32px;
      }
      .descriptionSection h4, .descriptionSection h5{
        font-size: 18px;
      }
      .cardBox .activity img{
        width: 30% !important;
      }
      .activity_row .cardBox {
        text-align: center !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
      }
      .activity__name{
        font-size: 14px;
      }
      .activity_row .paidbtn, .freebtn{
        position: static;
        font-size: 12px;

      }
  }

  /* product details css for standardization */


@media screen and (min-width:576.5px) {
  #productModal .modal-dialog{
    max-width: 700px !important;
  }

}

@media screen and (max-width:576.5px) {
  .mattress-details div label small{
    font-size: 12px !important;
  }
  .mattress-details div.col-6{
    border: 1px solid black !important;
    padding: 4px 0px;
  }
}