@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,600;0,700;1,400;1,500&display=swap');
*{font-family: montserrat;}
.navbar-brand img {width:80px ;cursor: pointer;}
.nav-item{padding-left: 20px;padding-right: 20px;}
.navIcons{width: 40px;cursor: pointer;}
.nav-link{color:white !important;font-weight: 600;}
.shadowClass , .navbar{
    z-index: 1;
    background: rgba(2,2,4, 0.35);
    /* box-shadow: 0px 4px 25px 10px rgba(0, 0, 0, 0.4); */
    box-shadow:  rgba(0, 0, 0, 0.5) 0px 3px 10px 5px;
}
.navList{
    margin-left: 1em;
    margin-right: 1em;
}
.simpleNav{
    position: relative;
}
::selection {
    color: black;
    background: #FFE23F;
  }
.userIcon , .signInIcon{
    box-shadow: none  !important;
    outline: none !important;
    border-radius: 50%;
    padding: 7px;
    padding: 8px 10px 7px 10px;
    /* padding-left: 9px;
    padding-right: 9px; */
    border: 2px solid ;
    font-weight: bold;
}

/* .userIcon{
    padding: 7px;
    padding: 7px 10px 7px 10px;} */
.user-icon{
    font-size: 22px;
    outline: none;
}
.user__dropdown .dropdown-toggle::after{
    display: none;
}
.user__dropdown  .dropdown-menu[data-bs-popper] {
    left: -20px;
}
.user__dropdown .dropdown-item{
    padding: 5px !important;
    text-align: center;
}
.user__dropdown .dropdown-item.active, .dropdown-item:active{
    background-color: #FFE23F;
    color: black;
}

@media screen and (min-width:991.5px) and (max-width:1241px){
    .nav-link{
        font-size: 15px;
    }
    .navIcons{
        width: 35px;
    }
    .user-icon{
        font-size: 20px;
    }
}

@media screen and (max-width:991.5px) {
    .navbar-nav-scroll{
        max-height: fit-content;
        height: 285px;
        text-align: end;
        justify-content: space-evenly;
    }
    .profile__section{
        justify-content: end;
    }
    .bg-md-light{
        background-color: white;
    }
    .hamburger{
        outline: none;
        border: none;
        /* border: solid 2px black !important; */
    }
    .hamburger:focus{
        outline: none;
        border: none;
        box-shadow: none;
    }
    .bannerTitle h1{
        font-size: 42px !important;
        text-align: center;
    }
    .bannerTitle{
        padding: 0px 46px !important ;
    }
}

@media screen and (max-width:576.5px) {
    .nav__logo{
        padding-left: 16px;
    }
    .navbar-brand img{
        width: 64px;
    }
    .hamburger{
        margin-right: 16px;
        outline: none;
        border: none;
        /* border: solid 2px black !important; */
    }
    .hamburger:focus{
        outline: none;
        border: none;
        box-shadow: none;
    }
    .p__zero{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    #mob_nav{
        height: 75px !important;
    }
    .user__dropdown  .dropdown-menu[data-bs-popper] {
        left: -100px;
    }
}