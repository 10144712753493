.search-container{
margin-top: 7em;
padding-left: 0.2em;
padding-bottom: 0.2em;
}
#search{
  border-radius: 2em;
}
#search:focus{
border-color: white;
box-shadow: none;
}