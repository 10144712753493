.exploreClass{
    margin-top: 10em !important;
}
@media screen and (max-width:768.5px){
    .exploreClass{
        margin-top: 8em !important;
    }
}
.heroSectionSearch{
    width: 30em;
}
.heroSectionSearch input:focus{
    border-color: black;
}
.heroSectionSearch input{
    border: 1px solid black;
}
.exploreMainDiv .slick-dots{
    bottom: -25px !important;
}
.exploreMainDiv{
    /* margin-top: -7%; */
}
.activeLocation img{
    /* filter: blur(1px); */
    border-radius: 12px;
    border: 3px solid black;
    box-shadow: 2px 2px 25px 2px rgba(70, 69, 69, 0.333);
}
.activeLocation h4{
    font-weight: 600;
}
.exploreLocParent{
    height: 55vh;
}
.locationParentDiv{
    height: 60vh !important;
}
.explore{
    height: fit-content;
    padding-bottom: 3%;
    padding-top: 3%;
}
.mainParentDiv{
    height: fit-content !important;
    padding-top: 2% !important;
    padding-bottom: 2% !important;
}
/* .dateAvailibilitySlider .slick-next::before{
    content: url(../assets/right-arrow.svg) !important;
    font-size: 5em;s
    color: yellow;
} */

.sSelected{
    top: 3%;
    right: 7%;
    color: #ffe23f;
    font-size: 2rem;
    border-radius: 50%;
    display: none;
    position: absolute;
}
.sShow{
    display: block;
}
.stickyDiv{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 20%;
    z-index: 1;
}
.stickyElement{
    background-color: white;
    width: 40px;
    height: 80px;
    margin-bottom:25% ;
    padding-top:26px;
    border-bottom-right-radius:  80px;
    border-top-right-radius: 80px;
    cursor: pointer;
    box-shadow: 2px 2px 2px 2px #00000027;
    background-color: #f5f5f5;
}
.stickyElement img{
    width: 70%;
    object-fit: cover;
}
.stayProducts .activityIcon img{
    width: 65%;
    cursor: pointer;
}
.activityIcon{
    width: 40px;
    text-align: left;
    margin-top: 4px;
}
.exploreActivityCard{
    width: 65% !important;
}
.exploreStay .stayProducts{
    height: 800px;
    overflow-y: scroll ;
}
.all_activity_cards{
    width: 90%;
}
.all_activity_cards .card-img-top{
    border-radius: 12px;
    height: 20em;
    object-fit: cover;
}
.all_activity_cards h5{
    font-size: 18px !important;
}

/***********************   Stay Cards CSS Start  ***********************************/


/* width */
.stayProducts::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .stayProducts::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.611);
  }
  
  /* Handle */
  .stayProducts::-webkit-scrollbar-thumb {
    background: #ffe23f;
    border-radius: 10px;

  }
  
  /* Handle on hover */
  .stayProducts::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: 0.2s ease !important;
  }

  #stayProductCard {
    flex-direction: row;
    height: 278px;
  }
  .details__col h2 , .price__col h2{
    font-size: 24px !important;
}
.details__col h5{
    font-size: 16px !important;
}

.stay__category{
    width: 75%;
    cursor: pointer;
}
.accomodation_notice{
    font-size: 11px;
}
.stayFeaturedImg{
    border-top-left-radius:12px !important;
    border-bottom-left-radius:12px !important;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: bottom;
    height: 100%;
}
.imgCol{
    border-bottom-left-radius:20px !important;
}
.stayProducts .stayFeaturedImg, .forImg .stayFeaturedImg{
    height: 79% !important;
}
.stayDetailsCard{
    border-bottom-right-radius:20px !important;
    border-top-right-radius:20px !important;
}
.imgCol{
    border-top-left-radius:20px !important;
    height: 350px;
}
.stay__category  img{
    border-radius: 12px;
    height: 15em;
    object-fit: cover;
}
.active__stay,.active__activity{
    border: 4px solid #ffe23f;
}
.active__activity{
    border-color: black !important;
}
/* #stayProductCard .imgCol{
    height: auto;
}
.stayProducts .main_278_h{
    border-radius: 20px;
    height: 278px;
} */

.availableDates h5{
    font-size: 18px;
}
.availableDates p{
    font-size: 14px;
}
.stay__date__carousel{
    height: fit-content;
    padding: 20px 0px 6px 0px;
    margin-top: -18px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.all_stay_cards{
    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.311);
}



/***********************   Stay Cards CSS Ends  ***********************************/
/* responsive */
@media screen and (max-width:1024.5px) {
    .stay__category .card-body .card-title{
        font-size: 18px;
    }
}
@media screen and (max-width:991.5px) {
    .stayProducts .card-text {
        font-size: 14px;
    }
    .stayProducts .imgCol .stayFeaturedImg{
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .stayProducts .imgCol {
        /* height: 305px; */
    }
    .stayProducts .card-body{
        padding: 0px !important;
    }
    .stay__category .card-body .card-title{
        font-size: 16px;
    }
    .stay__category {
        width: 100%;
    }
}
@media  screen and (max-width:768.5px) {
    .stayProducts .imgCol .stayFeaturedImg{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    .stayProducts .card-body{
        padding: 1rem 1rem !important;
    }
    #stayProductCard{
        flex-direction: column;
        height: auto;
    }
    .stayProducts .imgCol .stayFeaturedImg {
        height: auto !important;
    }
    .details__col h2 , .price__col h2{
        font-size: 20px !important;
    }
    .details__col h5{
        font-size: 16px !important;
        margin-bottom: 5px !important;
    }
    .description__details{
        margin-bottom: 5px !important;
    }
    .stay__category .card-body .card-title{
        font-size: 15px;
    }
    .stay__category {
        width: 100%;
    }
    .locationParentDiv{
        height: auto !important;
        padding: 3% 0%;
    }
}
@media  screen and (max-width:575.5px) {
    .price__col h2{
        font-size: 14px !important;
    }
    .details__col h5 img{
        width: 6% !important;
    }
    .details__col h5 {
        font-size: 14px !important;
        margin-bottom: 10px !important;
    }
    .stayProducts .imgCol .stayFeaturedImg {
        height: 80% !important;
    }
    .product__specifications{
        margin-top: -75px;
    }
    .stay__category {
        width: fit-content;
    }
    .stay__category  img{
        border-radius: 12px;
        height: 13em;
        object-fit: cover;
    }
    .stay__category .card-body{
        padding: 0.7rem 0.7rem;
    }
    .stay__category .card-body .card-title{
        font-size: 14px;
    }
    .description__details{
        display: none;
    }
    .action__btns button{
        font-size: 14px;
    }
    .svg-inline--fa{
        font-size: 22px !important;
    }
    .sSelected {
        top: -2%;
    }
    .mainDiv3 .homeActivityCards{
        width: 50% !important;
        height: 210px !important;
        padding: 16px;
    }
    .all_activity_cards .card-img-top{
        height: 16em;
    }  
    .all_activity_cards h5{
        font-size: 16px !important;
    } 
    .all_activity_cards .advButton{
        font-size: 14px;
        padding: 9px !important;
    }
    .all_activity_cards .navIcons{
        width: 34px;
    }
    .all_activity_cards .card-text{
        font-size: 14px;
    }
    .dateAvailibilitySlider .slick-prev {
        left:-9px
    }
    .dateAvailibilitySlider .slick-next{
        right: -2px;
    }
    .availableDates h5{
        font-size: 16px;
    }
    .availableDates p{
        font-size: 14px;
    }
    .stay-type-cards{
        flex-wrap: nowrap !important;
        overflow-x: scroll;
    }
    
}

@media screen and (max-width:490.5px) {
    .action__btns{
        display: flex;
        flex-direction: column !important;
    }
    .action__btns .advButton{
        font-size: 15px;
        width: 100%;
        margin: 0px !important;
        margin-bottom: 10px !important;
    }
    .details__col h2{
        font-size: 16px !important;
        /* width: max-content; */
    }
    .price__col h2{
        /* width: max-content;
        margin: auto; */
    }
}

/* 404 page and order-success CSS */
._404Parent{
    overflow-x: hidden;
    overflow-y: hidden;
    background: url('../assets/error.png');
    background-position: center;
}
._404Parent img{
    object-fit: cover;
}
._404Parent .error_msg{
    right: 20%;
}
._404Parent .error_msg h2, ._404Parent .error_msg h1{
    font-weight: 600;
    color: white;
}
._404Parent .error_msg h1{
    font-size: 50px;
}
 .notice-board img{
    width: 30%;
    height: 50%;
}
.notice-board{
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
@media screen and (max-width:1024.5px){
    ._404Parent .error_msg{
        right: 10%;
    }

}
@media screen and (max-width:576.5px){
    .notice-board img{
        width: 72%;
        height: 50%;
    }
    .notice-board h1{
        font-size: 25px;
    }
    .notice-board h3{
        font-size: 18px;
    }
}