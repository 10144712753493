/* stay slider css */
.slider__imgs{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    margin-top: 0px !important;
    border-radius: 12px;
}
.slider__cards{
    height: 350px !important;
    border-radius: 12px;
    text-align: center;
    margin: 0px ;
    border: 0;
    width: 85% !important;
   
} 
.slider__cards:hover{
    cursor:pointer;
}
.slider__div .slick-list{
    height: 450px !important;
}
.slider__cards>h4>a,.slick-slide>div>a{
    color: black !important;
    text-decoration: none !important;
}
.slider__cards h4{
    position: absolute;
    font-size: 20px;
    bottom: -15%;
    left: 0%;
    right: 0%;
  
}
.slider__div .slick-prev, .slider__div .slick-next {
    top: 40%;
}
.slider__div .slick-next{
    right: -10px ;
}
.slider__div .slick-prev{
    left: -20px;
}
.slider__div .slick-dots{
    bottom: 0px !important;
}
.slider__div .slick-dots li.slick-active button:before{
    color: #1c1c1c !important;
}
/* loction filter css */
.slider__div2 .slider__cards{
    height: 80px !important;
    border: 2px solid #1c1c1c;
} 
.slider__div2 .slick-list{
    height: 150px !important;
}
.slider__div2 .slider__cards h4 {
    top: 40% !important;
    bottom: 0%;
}
.slider__div2 .slick-prev, .slider__div2 .slick-next {
    top: 25%;
}
.slider__div2 .slick-dots {
    bottom: 20px !important;
}
.timeBar{
    top: 85%;
    left: 3%;
    background-color: black;
    padding: 7px;
    border-radius: 12px;
    color: white;
}
.activeLocation__slider__cards {
    background-color: #FFE23F;
    transition: 0.1s ease-in-out;
}
.activeLocation__slider__cards h4{
    font-weight: bold;
    transition: 0.1s ease-in-out;
}
.active__slider__cards img{
    border: 4px solid #FFE23F;
    transition: 0.1s ease-in-out;
}
.active__slider__cards h4{
    font-weight: bold;
    transition: 0.1s ease-in-out;
}

/* corporate events media query */
@media screen and (max-width:768px) {
    .timeBar{
        top: 80%;
    }
    .timeBar p{
        font-size: 13px;
    }
    .corporate__events,  .corporate__events h6{
        font-size: 14px;
    }
    .corporate__events .card-title {
        font-size: 17px;
    }
    .slider__div .card img {
        height: 19em !important;
    }
    .slider__cards, .slider__div2 .slider__cards{
        width: auto !important;
        height: auto !important;
    }
    .slider__cards h4 {
        position: static;
        padding: 7px 0px 7px 0px;
    }
    .slider__div2 .slider__cards{
        padding: 10px;
    }
    .slider__div2 .regular.slider{
        margin-bottom: 15px;
    }
}

@media screen and (max-width:576.5px){
    .event__pricings h6{
        text-align: left !important;
        margin-bottom: 0px;
    }
    .event__pricings p{
        text-align: left !important;
    }
    .event__date{
        margin-bottom: 0px;
    }
    .corporate__events{
        padding-top:1rem;
        padding-bottom:2rem;
    }
    .events__title{
        font-size: 32px !important;
    }
    .slider__cards h4{
        font-size: 18px;
    }
}