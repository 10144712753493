.footerParent{
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: black !important; 
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}
.footerRow h5 ,.footerRow  h5{
    color: white;
    font-weight: 600;
    padding-bottom: 10px;
}
.footerRow .links ,.footerRow  .links{
   list-style: none !important;
   text-align: left;
   padding: 0;
}
.footerRow .links li a , .footerRow  .links li a{
    color: white;
    text-decoration: none;
    padding-bottom: 4px;
}
.footerRow .links li a:hover , .footerRow  .links li a:hover{
    color: #FFE23F;
}
.footerRow  a img{
    width: 6em !important;
    padding-bottom: 2em;
}
.footerRow  .links a img{
    width: 3.5em !important;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
@media screen and (max-width:576.5px) {
    .footerParent{
        padding: 5% 0% 5% 0%;
    }
}