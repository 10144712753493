


.mystyle{
background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');
background-size: cover;
background-repeat: no-repeat;

}
.villa__container__parent{
    background-image: url(assets/villaBg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    --bs-gutter-x:0 !important;
}
.villa__container {
align-content: center;
overflow-x: hidden;
}
.villa_form_col {
    height: 100vh;
    backdrop-filter: blur(6px);
    background-color: #ffffff52;
}
.villa__title{
    color: #454545 !important;
}
.pt-10{
    padding-top: 10% !important;
}

.villa__container .card{
height: 400px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
}
.villa__container .card .card-header,.villa__container .card{
    border: none;
    background-color: transparent;
}
.villa__container input:focus{
    border-color: white;
    outline: none;
}

.social_icon span{
font-size: 60px;
margin-left: 10px;
color: #FFE23F;
}

.social_icon span:hover{
color: white;
cursor: pointer;
}

.card-header h3{
color: white;
}

.social_icon{
position: absolute;
right: 20px;
top: -45px;
}

.input-group-prepend span{
width: 50px;
background-color: #FFE23F;
color: black;
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;

}

.remember{
color: white;
}

.remember input
{
width: 20px;
height: 20px;
margin-left: 15px;
margin-right: 5px;
}

.login_btn{
color: black;
background-color: #FFE23F;
width: 100px;
outline: none;
border: none;
}

.login_btn:hover{
color: #FFE23F;
background-color: black;    
}

.links{
color: white;
}

.links a{
margin-left: 4px;
}
.villa__container .input-group-text svg{
    font-size: 25px;
}
.villa__container .input-group-text svg:hover{
    transform: scale(1.15);
    transition: 0.9 ease-in;
}

.villa_form_col .input-group input, .villa_form_col .input-group select, .villa_form_col .input-group select> option{
    background-color: #6fc4de89;
    padding: 12px;
    border-radius: 30px;
    border: none;
    color: black;
}
.villa_form_col .input-group select> option{
    background-color: #6FC5DE !important;
}
.login_btn{
    padding: 12px;
    border-radius: 30px;
    background-color: #FFE23F;
    width: 100%;
}
.villa_form_col .input-group select:hover{
    cursor: pointer;
}
.villa_form_col .input-group input::placeholder, .villa_form_col .input-group select{
    color: rgba(21, 20, 20, 0.622);
}

@media screen and (max-width:1024.5px) {
    .pt-10{
        padding-top: 20% !important;
    }
}
@media screen and (max-width:576.5px) {
    .pt-10{
        padding-top: 30% !important;
    }
}
@media screen and (max-width:426.5px) {
    .pt-10{
        padding-top: 40% !important;
    }
}