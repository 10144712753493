
.bg-dark{
    background-color: black !important;
}
.bg-light{
    background-color: white !important;
}
.text-dark{
    color: black !important;
}
.bg-yellow{
    background-color: #FFE23F !important;
}
.text-yellow{
    color: #FFE23F !important;
}
.hover__class:hover{
    border:1px solid #FFE23F; 

}

.overview{
    height:50vh;
    left: 0;
    right: 0;
    top: -205px;
    z-index: -1;
}

.sectionHeading h1 span{
    font-family: blanquotey  !important;
}
.sectionHeading h1{
    color: #FFE23F;
    text-align: center;
    font-size: 3.1em;
    letter-spacing: 0.2em;
    margin-top: -1em;
    margin-bottom: 0.3em;
}
.sectionHr{
    width: 43%;
    opacity: 1 !important;
}
.selectedSites{
    height: auto;  /* previously 100vh  */
    padding-top: 1px;
    padding-bottom: 3%;
}
/* Carousel 1 CSS */

.mainDiv1 .slick-slider{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainDiv1 .slick-prev,.mainDiv1
.slick-next {
    position: absolute;
}
.mainDiv1 .slick-next{
    right: 10px;
}
.mainDiv1 .slick-prev{
    left: 10px;
}
.carousel1Cards1{
    background: url(../assets/img1.png)  ;
}
/* .carousel1Cards{
    width: 75%  ;
    border-radius: 20px !important;
    background-position: inherit !important;
    cursor: pointer;
    background-repeat:no-repeat !important;
    margin: auto !important;
    background-color: transparent !important;
    border: none;
}
.carousel1Cards img{
    border-radius: 24px !important;
    object-fit: cover;
    width: -moz-available;          
    width: -webkit-fill-available; 
    width: fill-available;
}
.carousel1Cards h3 {
    padding-top: 20px;
    color: #fff;
} */

.carousel1Cards img{
    margin: auto;
    text-align: center;
    width: 65%;
    height: 22em;
    border-radius: 12px;
}
.slick-dots li.slick-active button:before{ 
    color: #FFE23F !important; 
} 
.slick-dots button:before{ 
    color:#ccc !important; 
}
.mainDiv1 .slick-slide{
    transform: scale(0.90) !important;
    transition:0.2s !important;
}
.mainDiv1 .slick-active {
    transform: scale(1.01) !important;
    transition:0.5s !important;
}
.mainDiv1 .slick-center{
    transform: scale(1.10) !important;
    transition:0.5s !important;
}
.mainDiv1 .slick-dots li button:before{
    font-size: 10px !important;
    color: #ccc !important;
}
.mainDiv1 .slick-dots li.slick-active button:before{
    color: #FFE23F !important;
}

.mainDiv2 .slick-slide{
    transform: scale(0.75) !important;
    transition:0.2s !important;
}
.mainDiv1 .slick-list{
    padding-top:30px !important;
    padding-bottom: 40px !important;
}
.mainDiv1 .slick-dots{
    bottom:-5px !important;
}

/* adventrio locations */
.parentDiv{
    height: auto !important;
}
.sectionTitle h1{
    color: black;
    font-size: 3em;
    font-weight: 500;
    text-align: center;
}
.headingSection .sectionHr{
    color: black;
    width: 25% ;
}
.wrapperDiv{
    padding-top: 3%;
    position: relative;
    text-align: center;
    /* height: 60vh; */
    margin: 0em 2em 0em 2em ;
}
.mainDiv2 .card{
    width: 100% !important;
    height: 260px !important;
    border-radius: 20px;
    text-align: -webkit-center;
    border: none !important;
    outline: none !important;
}
.mainDiv2 .card a:focus{
    border: none !important;
    outline: none !important;
}
.mainDiv2 .card h4{
    position: absolute;
    bottom: -35%;
    left: 0%;
    right: 0%;
    text-transform: capitalize;

}
.mainDiv2 .card img{
    width: 96% ;
    border-radius: 12px !important;
    height: 19em  !important;
    object-fit: cover;
}
.mainDiv2 .slick-list{
    height: 20em !important;
}
.mainDiv2 .slick-dots{
    bottom:85px !important;
}
.mainDiv2 .slick-prev,.mainDiv2
.slick-next {
    position: absolute;
}
.mainDiv2 .slick-next{
    right: -0.4%;
    top:45%;
}
.mainDiv2 .slick-prev{
    left: -1%;
    top:45%;
}

.mainDiv2 .slick-active{
    transform: scale(0.85) !important;
    transition:0.5s !important;
}
.slick-next::before{
    content: url(../assets/next.svg) !important;
    opacity: 1 !important;
}
.slick-prev::before{
    content: url(../assets/prev.svg) !important;
    opacity: 1 !important;
}
.advButton{
    background-color: #FFE23F;
    color: black;
    border-radius: 20px;
    border: none;
    padding: 0.6em 1.5em 0.6em 1.5em;
}
.advButton:hover{
    background-color: black;
    color: #FFE23F;
    transition:0.4s ease-out !important;
}
.advButtonDisabled{
    color: #000;
    opacity: 0.3;
    background: #FFE23F ;
    border-radius: 20px;
    border: none;
    padding: 0.6em 1.5em 0.6em 1.5em;
}
.exploreMoreSeciton .exploreImg{
    margin-top: -2em;
    width: 100%;
}

/* Adventrio activities css */
.activities{
    height: 60vh;
}
.mainDiv3 .card{
    height: 250px !important;
    border-radius: 12px;
    text-align: center;
}
.mainDiv3 .homeActivityCards{
    width: 80% !important;
}
.mainDiv3 .card img{
    height: max-content  !important;
    object-fit: none;
    margin: auto;
    margin-top: 15%;
}
.mainDiv3 .card h4{
    position: absolute;
    font-size: 20px;
    bottom: 10%;
    left: 0%;
    right: 0%;
}
.mainDiv3 .slick-next{
    right: 0%;
    top:48%;
}
.mainDiv3 .slick-prev{
    left: -1%;
    top:48%;
}
.mainDiv3 .slick-dots li.slick-active button:before{ 
    color: #1c1c1c !important; 
} 
.slick-dots button:before{ 
    color:#333 !important; 
    font-size: 10px  !important;
    bottom:-45px !important;
}
.mainDiv3 .slick-dots{
    bottom: -35px !important;
}

/* adventrio travel css */
.travelDiv{
    height: 65vh;
}
.mainDiv4 .card{
    width: 18rem !important;
    height: 20rem !important;
    border-radius: 20px;
    text-align: center;
    margin-left: auto ;
    margin-right: auto;
    justify-content: center !important;
    align-items: center;
    border: 1px solid black;
    cursor: pointer;
}
.mainDiv4 .card img{
    width: 12rem;
}

/* adventrio blogs */

/* .ourblogs .mainDiv5 .slick-slider .slick-dots{
    /* margin-bottom: 20px;
} */

.mainDiv5 .card{
    width: 100%  !important;
    height: max-content !important;
    border-radius: 20px;
    text-align: center;
    margin-left: auto ;
    margin-right: auto;
    justify-content: center !important;
    align-items: center;
    cursor: pointer;
}
.mainDiv5 .card img{
    padding: 0.2em;
    padding-bottom: 0em;
    width: 100%;
}
.mainDiv5 .card .card-body{
    padding: 1em !important;
    padding-top: 0px !important;
}
.mainDiv5 .card .card-body .card-title{
    text-align: left !important;
}
.mainDiv5 .card .card-body p{
    text-align: left !important;
}
 .read-more small{
    color: black !important;
}
.ourblogs{
    height: 90vh !important;
    display: none;
}
/* adventrio stay */
.stay{
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.stay .mainDiv6 .premia img{
    width: 490px;
    max-height:80vh;
    filter:blur(1.5px);
    -webkit-filter: blur(1.5px);
    border-radius: 20px;
}

.stay .mainDiv6 .notice{
    position: absolute;
    top: 36%;
    left: 0%;
    right: 0%;
}
.stay .mainDiv6 .premia .notice h1{
    font-family: blanquotey;
    letter-spacing: 0.2em;
    font-size: 3.5vw !important;
    color: black ;
}
.stay .mainDiv6 .premia .notice h2{
    font-size: 3.5vw;
    font-weight: bold;
    color: black;
}
.stay .mainDiv6 .advBtnDiv{
    margin-top: 10px;
}
.stay .mainDiv6 .advButton{
   margin-top: -400px !important;
}
.grid{
    columns: 4;
    column-gap: 0;
}
.grid-item{
    width: 85%;
    margin-bottom: 25px;
    cursor: pointer;
}
.grid-item img{
    width: 100%;
    break-inside: avoid;
    cursor: pointer;
    border-radius: 20px;
    height: 160px;
    object-fit: cover;
}
.grid-item1 img{
    min-height: 320px;
}
.grid-item2 img {
    min-height: 370px;
}
.grid-item3 img{
    max-height: 260px;
}
.mainDiv6 .stay__type__overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.414);
}

/* responsive */

@media screen and (min-width:769px) and (max-width:1024px){
    .exploreMoreSeciton .exploreImg{
        width: 100% !important;
    }
    .mainDiv4 .card img{
        width: 8rem !important;
    }
    .mainDiv4 .card {
        width: 14rem !important;
        height: 16rem !important;
    }
    /* .stay .mainDiv6 .premia img{
        max-height: 45vh;
        object-fit: cover;
        margin-bottom: 25px;
    } */
    .stay__type{
        font-size: 14px;
    }
    .activities{
        height: 65vh;
    }
    
}
@media screen and (max-width:991.5px) {
    .premia {
        /* display: none; */
        width: 100%;
        margin-bottom: 40px;
    }
    .premia a div img{
        object-fit: cover;
        object-position: bottom;
    }
    .masonary__part{
        width: 100%;
    }
}

@media screen and (max-width:768.5px) {

    .sectionHeading h1{
        font-size: 2.8em;
    }
    .sectionTitle h1{
        font-size: 2.5em;
    }
    .exploreImg{
        margin-top: -15px;
    }
    .stay .mainDiv6 .premia .notice h1 , .stay .mainDiv6 .premia .notice h2{
        font-size: 7vw !important;
        /* display: none !important; */
    }
    .stay .mainDiv6 .premia img{
        width: 300px;
        height:450px;
        /* display: none !important; */
    }
    .grid-item img{
        width: 100%;
    }
    .grid-item{
        margin-bottom: 15px;
    }
    .grid{
        column-gap: 15px;
    }
    .mainDiv4 .card img{
        width: 4rem !important;
    }
    .mainDiv4 .card {
        margin-bottom: 10px;
        width: auto !important;
        height: auto !important;
    }
    .stay__type{
        font-size: 14px;
    }
    .carousel1Cards{
        width: 30% ;
    }
    .carousel1Cards img{
        width: 100%;
        height: auto;
    }
    .carousel1Cards h4{
        font-size: 20px;
    }
    .mainDiv1 .slick-dots{
        bottom: -5px !important;
    }
    .carousel1Cards h3{
        font-size: 19px;
    }
    .selectedSites{
        padding: 3%;
    }
    .mainDiv2 .slick-prev{
        left: -1.5%;
    }
    /* .mainDiv2 .card img{
        width: 100%;
        height: auto !important;
    } */
    .grid-item2 img{
        min-height: 300px !important;
    }
    .stay{
        height: auto;
    }
    .non_slicky{
        display: block;
        width: 100%;
        overflow-x:scroll;
    }
    .non_slicky .regular.slider{
        display: block;
        white-space: nowrap;
        margin-bottom:15px;   /* scrollbar margin bottom */
    }
    .overviewParent .mainDiv2 .regular.slider , .locationParentDiv .mainDiv2 .regular.slider {
        margin-bottom: 5px;
    }
    .selectedSites .mainDiv1 .regular.slider ,   .slider__div .regular.slider{
        margin-bottom: 0px;
    }
    .non_slicky .card, .non_slicky .carousel1Cards{
        display: inline-block;
        margin-right: 10px;
    }
    .mainDiv2 .card{
        width: auto !important;
        height: auto !important;
    }
    .mainDiv2 .card h4{
        position: static;
    }
}

@media screen and (max-width:576.5px) {
    .mainDiv3 .card img{
        height: auto  !important;
        object-fit: cover;
        width: 45%;
        margin: auto;
        margin-top: 15%;
    }
    .carousel1Cards{
        width: 40% ;
    }
    .carousel1Cards h4{
        font-size: 16px;
    }
    .grid-item2 img{
        min-height: 175px !important;
    }
    .grid{
        columns: 3;
    }
    .stay{
        padding-top: 1rem;
    }
    .grid-item1 img{
        min-height: 270px;
    }
    .stay .mainDiv6 .advBtnDiv{
        margin:30px 0px 0px 0px;
    }
    .stay{
        height: auto;
    }
    .mainDiv1 .slick-dots{
        bottom: 25px !important;
    }
    .wrapperDiv{
        margin: 0em 1em;
    }
    .mainDiv2 .card img{
        height: 14em !important; 
    }
    /* font sizes for homepage */
    .sectionHeading h1{
        font-size: 36px;
    }
    .sectionTitle h1{
        font-size: 32px; /* for all section UI */
    }
    /* below is for names of location at home page loc slider */
    .mainDiv2 .card h4, .mainDiv3 .card h4{
        font-size: 18px;
        padding-top: 10px;
    }
    .parentDiv {
        padding: 15px 0px;
    }
    /* button  */
    .exploreMoreSeciton{
        margin-top: 10px;
    }
    .mainDiv3 .card{
        width: 50%  ;
    }
}

@media screen and (max-width:480.5px) {

    .grid-item2 img{
        min-height: 190px !important;
    }
    .grid{
        columns: 3;
    }

}

/* Login and signup modal css */
.modal_css{
    height: 1.5em !important;
    border-radius: 0px;
    border: none;
    border-bottom: 2px solid black;
    padding: 0px !important;
}



