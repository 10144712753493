.qty button{
    width: 2em;
    height: 2em;
}
.qty input{
    border: 1px solid #FFE23F;
    outline:none !important;
}
.couter-input{
    width: 20% ;
}
.user-icon img{
    width: 3% ;
}
.cartProducts .imgCol {
    height: 278px;
}
.cartPriceCard{
    position: sticky;
    top: 75px;
    height: 100px;
    right: 0%;
}
.cart_card_cost{
    font-size: 15px;
}
@media screen and (max-width:768.5px){
    .row_margin_stay{
        margin-bottom: 10rem;
    }
    .all_promocodes_list{
        height: 130px !important;
        position: static !important;
    }
    .cartPriceCard{
        position: fixed;
        top:auto;
        height: auto;
        right: 0;
        bottom: 0% !important;
        background-color: #fff;
        padding: 1rem 0rem 0rem 0rem !important;
    }
    .cartPriceCard .container-fluid{
        padding: 0px 12px;
    }
    .cartPriceCard .submitPromo{
        top: -9%;
    }
    .submitPromo{
        margin-top: 3.5px !important;
    }
    .cartPriceCard button{
        font-size: 14px;
    }
    .cart_card_cost{
        font-size: 14px !important;
        padding-bottom: 4px;
    }
}
@media screen and (max-width:576.5px){
    .cartProducts .product__specifications{
        margin-top: 0px ;
    }
    .cartPriceCard {
        position: fixed !important;
        top: auto;
        bottom: 0 !important;
        right: 0 !important;
        height: auto !important;

    }

}
@media screen and (max-width:425px) {
    .user-icon img{
        width: 8%;
    }
    .couter-input{
        width: 35%;
    }
    .cart-desc , .user-icon{
        font-size: 14px;
    }
}

/* Checkout Page CSS */
.checkoutTable .table>:not(caption)>*>*{
    padding:  1rem  1rem  !important;
} 
.checkoutTable{
    border: 1px solid black !important;
    border-radius: 20px ;
}
.checkOutParent{
    margin-top: 10em;
}
.priceSection{
    font-size: 14px;
}
.submitPromo{
    top: 0%;
    right: 3%;
    background-color: #FFE23F;
    border: none;
    padding: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    outline:none;
    margin-top: 7px;
    border-radius: 6px;
}
.submitPromo:hover{
    background-color: black;
    color: #FFE23F;
}
.promocode input{
    border: 1px solid #ccc;
}

.promocode input:focus {
    border: 2px solid #FFE23F !important;
    outline: none;
}
.closeModal{
    top: 3%;
    right: 10%;
    font-size: 20px;
    cursor: pointer;
}
#checkedInDate::-webkit-calendar-picker-indicator{
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  
  #checkedOutDate::-webkit-calendar-picker-indicator{
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  .checkOutParent .form-floating>.form-control{
    padding: 0px;
    padding-right: 10px;
    padding-left: 10px;
    height: 30px !important;
    outline: none;
    box-shadow: none;
  }
  .checkInDates input, .checkOutDates input,.checkOutDates select{
    background-color: white;
  }
  .all_promocodes_list{
    backdrop-filter: blur(8px);
    background-color: white;
    border: 2px dashed black;
    padding: 16px !important;
    min-height: 150px;
    height: 200px;
    overflow-y: auto;
  }
  .all_promocodes_list::-webkit-scrollbar {
    width: 5px;
    scroll-margin: 20px;
  }
  
  /* Track */
  .all_promocodes_list::-webkit-scrollbar-track {
    background: whitesmoke;
  }
  
  /* Handle */
  .all_promocodes_list::-webkit-scrollbar-thumb {
    background: #ffe23f;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .all_promocodes_list::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: 0.2s ease !important;
  }
  .single_promoCode p, .single_promoCode button{
    font-size: 12px;
    /* border-bottom:  1px dashed black; */
  }
  .single_promoCode{
    background-color: rgba(81, 81, 81, 0.13);
    padding: 10px;
    margin: 10px 0px;
    border: 2px dashed black;
  }
@media screen and (max-width:1024.5px) {
    .checkOutParent{
        /* margin-top: 35%; */
    }
    .cart-desc{
        font-size: 15px;
    }
    .cart__card .card-title{
        font-size: 28px;
    }
    .cart__card .price h5{
        font-size: 18px;
    }
}
@media screen and (max-width:991px) {
    .checkOutRow{
        padding: 0px !important;
    }
    .checkOutRow input[type="date"]{
        width: 100%;
    }
    .imgCol .stayFeaturedImg {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        object-position: center;
    }
}
@media screen and (max-width:768.5px) {
    .checkOutParent{
        /* margin-top: 35%; */
    }
    .h-300px{
        height: 300px;
    }
    .checkoutImg .stayFeaturedImg,.checkOutRow .stayFeaturedImg  {
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
        object-position: center;
    }
    .footerCart{
        display: none;
    }
    .payment__process{
        position: fixed;
        bottom: 0%;
    }
    .payment__process .row .col-md-6 h4 , .payment__process .row .col-md-6 button{
        font-size: 18px;
    }
    .total_guest{
        margin-top:-25px;
    }
}


/* Wishlist page CSS */
.trash-icon {
    position: absolute;
    top: 30px;
    right: 2%;
    cursor: pointer;
}
.cart-trash{
    top: 20px;
    font-size: 18px;
}
.wishlistHovering:hover ,.wishlistHovering{
    text-decoration: none !important;
    color: black;
}
.wishlistHovering .cartProducts h4:hover{
    color: rgb(98, 98, 98) !important;
}
.wishlistImgCol{
    height: 270px !important;
}
.cartProducts div svg:hover , .all_activity_cards div svg:hover{
    color: rgba(0, 0, 0, 0.423) !important;
}

/* media query for wishlist UI  */
@media screen and (max-width:1199.5px) {
    .wishlistImgCol .stayFeaturedImg{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        object-position: center;
    }
}
@media screen and (max-width:768.5px){
    .wishlist__desc{
        font-size: 14px;
    }
}